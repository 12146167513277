<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row
          align-h="end"
        >
          <!-- Search -->
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-start justify-content-start mt-2">
              <div>
                <p class="mr-1">
                  Exibir empresa Imediato?
                </p>
              </div>
              <b-form-checkbox
                :checked="showImediato"
                class="custom-control-secondary"
                name="check-button"
                switch
                @input="showImediato = !showImediato; institutionId = undefined;"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>

            </div>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <label for="institution-id">Instituições</label>
            <v-select
              id="institution-id"
              v-model="institutionId"
              name="institution-id"
              :options="institutionOptions"
              label="label"
              item-value="value"
              item-text="label"
              :disabled="showImediato"
            >
              <span slot="no-options">Nenhuma instituição encontrada.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="4"
          >
            <div class="d-flex align-items-end justify-content-end mt-2">
              <b-button
                class="mr-1"
                variant="outline-primary"
                :to="{name: 'canceled-advanced-history'}"
              >
                Cancelamentos
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="d-none d-xl-inline"
                />
              </b-button>
              <b-button
                variant="primary"
                @click="showAuthorize()"
              >
                <span class="-text-nowrap">
                  Gerar <span class="d-none d-xl-inline">remessa</span>
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="contractNotInPaymentRemittance"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="(item) => { item.checked = !item.checked }"
      >
        <template
          #head(actionsCheck)=""
        >
          <b-form-checkbox v-model="checkAll" />
        </template>

        <!-- Column: contract_code -->
        <template
          #cell(contract_code)="{ item }"
          class="w-100 "
        >
          <div class="d-flex flex-column justify-content-center align-items-center">
            <strong>{{ item.contract_code }}</strong>
            <b-badge
              v-if="item.tag_test !== ''"
              pill
              class="text-capitalize mx-1 "
              variant="warning"
            >
              {{ item.tag_test }}
            </b-badge>
            <b-badge
              pill
              class=""
              :variant="item.contract_person_type === 'PF' ? 'light-success' : 'light-danger'"
            >
              {{ item.contract_person_type }}
            </b-badge>
          </div>
        </template>

        <template
          #cell(actionsCheck)="{ item }"
        >
          <b-form-checkbox
            v-model="item.checked"
            :class="{'checked': item.checked}"
            :name="`event-filter-${item.id}`"
          />
        </template>

        <!-- Column: signed_at -->
        <template
          #cell(signed_at)="{ item }"
          class="w-100"
        >
          {{ formatDateTimeDDMMYYHHMM(item.signed_at) }}
        </template>

        <!-- Column: User -->
        <template
          #cell(user)="{ item }"
          class="w-100"
        >
          <div>{{ item.name }}</div>
          <div
            v-if="item.physician_company_name"
            class="account-info"
          >
            <div>Empresa</div>
            <div :title="item.physician_company_name">
              {{ truncateString(item.physician_company_name, 20) }}
            </div>
            <div>{{ formatCnpj(item.physician_company_cnpj) }}</div>
          </div>
        </template>

        <template
          #cell(account)="{ item }"
          class="w-100"
        >
          <div class="d-flex flex-column">
            <div class="account-info">
              <div>Banco</div>
              <div>{{ item.bank_code }} - {{ item.bank_name }}</div>
            </div>
            <div class="d-flex">
              <div class="account-info">
                <div>Agência</div>
                <div>{{ item.branch_number }}<span v-if="item.branch_code">-{{ item.branch_code }}</span></div>
              </div>
              <div class="account-info">
                <div>{{ resolveAccountType(item.account_type) }}</div>
                <div>{{ item.account_number }}-{{ item.account_code }}</div>
              </div>
            </div>
          </div>
        </template>

        <template
          #cell(amount_physician_duty)="{ item }"
          class="w-100"
        >
          <div>
            {{ formatCurrency(item.amount_physician_duty) }}
          </div>
          <div>
            <b-badge
              pill
              class=""
              :variant="item.source === 'physician_duty' ? 'light-success' : 'light-danger'"
            >
              {{ item.source === 'physician_duty' ? 'Plantão' : 'Serviço' }}
            </b-badge>
          </div>
        </template>

        <!-- Column: deadline -->
        <template #cell(deadline)="{ item }">
          <b-badge
            pill
            :variant="`${resolveDeadlineVariant(item.deadline_level)}`"
            class="text-capitalize"
          >
            {{ item.deadline }}
          </b-badge>
        </template>

        <template
          #cell(actions)="{ item }"
        >
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click.prevent.stop="openDelete(item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancelar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-paymentRemittance"
      ref="paymentRemittanceModal"
      title="Criar remessa"
      hide-footer
      centered
    >
      <div>
        <p>Total <strong>{{ formatCurrency(remittanceTotal) }}</strong></p>
        <p>Informe a data de pagamento</p>
        <div class="d-flex">
          <b-form-datepicker
            id="payment-date"
            v-model="paymentDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
            label-no-date-selected="Selecione uma data"
            :min="new Date()"
          />
          <b-button
            variant="primary"
            class="ml-1 w-100"
            :disabled="loading"
            @click="authorize"
          >
            <span class="d-none d-sm-inline">Criar remessa</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-contract"
      ref="contractCancelModal"
      :title="`Deseja cancelar a antecipação?`"
      hide-footer
      centered
    >
      <div>
        <b-alert
          show
          variant="danger"
          class="p-1"
        >
          Ao cancelar a antecipação, a depender da data do plantão, o médico poderá antecipar novamente.
        </b-alert>
        <b-form-group
          class="mt-1"
          label="Motivo"
          label-for="reopen-motive"
        >
          <b-form-textarea
            id="reopen-motive"
            v-model="contractToCancel.motive"
            rows="4"
            placeholder="Descreva o motivo..."
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="loading"
            @click="deleteItem"
          >
            <span class="d-none d-sm-inline">Cancelar</span>
            <feather-icon
              icon="RefreshCwIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BPagination,
  BButton,
  BFormCheckbox,
  BModal,
  BFormDatepicker,
  BDropdownItem,
  BDropdown,
  BFormTextarea,
  BFormGroup,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import {
  formatCnpj, formatCurrency, formatDateTimeDDMMYYHHMM, resolveAccountType, truncateString,
} from '@core/utils/utils'
import Vue from 'vue'
import vSelect from 'vue-select'
import reportStoreModule from '@/views/report/reportStoreModule'
import useNotInPaymentRemittance from './useNotInPaymentRemittanceList'
import contractStoreModule from '../contractStoreModule'

export default {
  components: {
    BAlert,
    BFormGroup,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BModal,
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BPagination,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  methods: { truncateString, formatCnpj, resolveAccountType },
  setup() {
    const CONTRACT_APP_STORE_MODULE_NAME = 'app-contract'
    const REPORTS_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const paymentDate = ref('')
    const loading = ref(false)
    const currentUserId = ref(null)
    const paymentRemittanceModal = ref(null)
    const paymentRemittance = ref(null)
    const contractCancelModal = ref(null)
    const contractToCancel = ref({
      contract_id: '',
      motive: '',
    })

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    function load() {
      //
    }

    const {
      contractNotInPaymentRemittance,
      fetchInstitutions,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,
      checkAll,
      showImediato,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      createPaymentRemittance,
      institutionId,
      institutionOptions,
      remittanceTotal,
    } = useNotInPaymentRemittance()

    fetchInstitutions()

    async function authorize() {
      if (paymentDate.value === '') return
      try {
        loading.value = true
        paymentRemittance.value = null
        paymentRemittance.value = await createPaymentRemittance(paymentDate.value)
        loading.value = false
        paymentRemittanceModal.value.hide()
        paymentDate.value = ''
        refetchData()

        const text = `Acessar remessa Nº${paymentRemittance.value.code}`
        const resultCreated = await Vue.swal({
          title: 'Remessa criado!',
          text,
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Acessar',
          cancelButtonText: 'Fechar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        })
        if (resultCreated.value) {
          this.$router.push({
            name: 'payment-remittance-view',
            params: { id: paymentRemittance.value.id },
          })
        }
      } catch (err) {
        loading.value = false
        throw err
      }
    }

    async function showAuthorize() {
      paymentRemittanceModal.value.show()
    }

    async function openDelete(item) {
      contractToCancel.value = {
        contract_id: item.id,
        motive: '',
      }
      contractCancelModal.value.show()
    }
    async function deleteItem() {
      store.dispatch('app-contract/deleteContractAdvanced', contractToCancel.value)
        .then(async () => {
          refetchData()
          contractCancelModal.value.hide()
          await Vue.swal({
            title: 'Removido!',
            text: 'Removido',
            icon: 'success',
            customClass: { confirmButton: 'btn btn-primary' },
            buttonsStyling: false,
          })
          contractToCancel.value = {
            payment_remittance_item_id: '',
            motive: '',
          }
        })
    }

    return {
      checkAll,
      openDelete,
      deleteItem,
      contractToCancel,
      contractCancelModal,
      loading,

      remittanceTotal,
      contractNotInPaymentRemittance,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      authorize,
      paymentDate,
      showImediato,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,

      currentUserId,
      roleOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      load,
      formatCurrency,
      formatDateTimeDDMMYYHHMM,
      createPaymentRemittance,
      paymentRemittanceModal,
      paymentRemittance,
      showAuthorize,
      institutionId,
      institutionOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dates-row {
  max-width: 500px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

tr:has(.custom-checkbox.checked) {
  background-color: rgba(98, 98, 98, 0.05);
}
.account-info {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  div {
    font-size: 13px;
    line-height: 13px;
  }
  div:first-child {
    font-size: 10px;
    color: #959a9c;
  }
}
</style>
